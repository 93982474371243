@import '../scss/variables.scss';

.site-nav {
  grid-column: 1 / 2;
  text-align: left;
  min-width: 110px;
  h1,
  h2,
  p {
    margin: 0;
  }
}
.flex-vert {
  display: flex;
  flex-direction: column;
}
.logo-box {
  color: #ffffff;
  background-color: #393939;
  margin-top:5%;
  max-width: 155px;
  padding: 0.75rem 1.5rem;
}
.nav-border-radius {
  border-radius: 0 .25rem .25rem 0;
}
.nav-box {
  color: #393939;
  cursor: pointer;
  display: flex;
  margin-top: 1rem;
}
.nav-box:hover > .bl-color-block {
  width: 1.25rem;
}
.nav-text {
  margin-left: 0.75rem;
  font-size: 0.8rem;
}
.bl-color-block {
  width: 0.5rem;
  transition: width 0.3s ease-in-out;
}
.battle-blue_bg {
  background-color: $battle-blue;
}
.battle-purple_bg {
  background-color: $battle-purple;
}
.battle-dark-purple_bg {
  background-color: $battle-dark-purple;
}
.battle-orange_bg {
  background-color: $battle-orange;
}
.battle-dark-orange_bg {
  background-color: $battle-dark-orange;
}
