
body {
  background-color: #f3f3f3;
  color: rgb(57, 57, 57);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  margin: 0;
}
form {
  input, textarea {
    border: 1px solid rgb(57, 57, 57);
    border-radius: .25rem;
    display: block;
    font-size: .9rem;
    margin: 0.625rem 0;
    padding: .75rem;
    width: 100%;
  }
  input[type=submit] {
    margin-top: 2.5rem;
    cursor: pointer;
  }
  textarea {
    height: 5.625rem;
  }
}
