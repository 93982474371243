@import './scss/variables.scss';
* {
  box-sizing: border-box;
}
.form-container {
  margin-top: 3rem;
  max-width: 30.625rem;
}
.center {
  margin-left: auto !important;
  margin-right: auto !important;
}
.text-left {
  text-align: left;
}
.mt-1 {
  margin-top: 1rem;
}
.btn-primary {
  background-color: #fafafa;
  border-color: $battle-blue;
  color: $battle-blue;
  cursor: pointer;
  font-size: 1rem;
  transition: all .5s ease-in-out;
  &:hover {
    background-color: $battle-blue;
    color: white;
  }
}
.text-link {
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  position: relative;
  -webkit-appearance: none;
  &:hover {
    color: $battle-blue;
  }
  &::after {
    content: '';
    height: 1px;
    background-color: rgba(57, 57, 57, .8);
    background: linear-gradient(90deg, rgba(243,243,243, 1) 0%, rgba(57,57,57,1) 10%, rgba(57,57,57,1) 90%, rgba(243,243,243, 1) 100%);
    position: absolute;
    top: 1.5rem;
    left: 0;
    transition: width .3s;
    width: 100%;
  }
  &:hover::after {
    width: 0;
  }
}
.App {
  text-align: center;
  font-size: 1rem;
}
.grid-layout {
  width: 100%;
  display: grid;
  grid-gap: 1.5%;
  grid-template-columns: repeat(8, 11%);
  margin: 0 auto;
  margin-bottom: 2em;
}
.main-content {
  grid-column: 4 / 8;
}

@media screen and (min-width: 580px) {
  .main-content {
    grid-column: 4 / 7
  }
}
@media screen and (min-width: 880px) {
  .main-content {
    grid-column: 4 / 6
  }
}
@media screen and (min-width: 1080px) {
  .main-content {
    grid-column: 4 / 7
  }
}


